import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import georedirect_45global from "/vercel/path0/middleware/georedirect.global.js";
import redirect_45trailing_45slash_45global from "/vercel/path0/middleware/redirect-trailing-slash.global.js";
import redirect_45global from "/vercel/path0/middleware/redirect.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  georedirect_45global,
  redirect_45trailing_45slash_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sanctum:auth": () => import("/vercel/path0/modules/m/nuxt-auth-sanctum-main/runtime/middleware/sanctum.auth.ts")
}