
<template>
    <svg class="svg-shape" v-html="svgIcon">
    </svg>
</template>

<script setup>
    const props = defineProps(['type']);
    const headerSvgs = ['round', 'oval', 'cushion', 'baguette', 'pear', 'emerald', 'marquise', 'asscher', 'radiant', 'princess', 'heart',
     'round_active', 'oval_active', 'cushion_active', 'baguette_active', 'pear_active', 'emerald_active', 'marquise_active', 'asscher_active', 'radiant_active', 'princess_active', 'heart_active',
     'round_active_green', 'oval_active_green', 'cushion_active_green', 'baguette_active_green', 'pear_active_green', 'emerald_active_green', 'marquise_active_green', 'asscher_active_green', 'radiant_active_green', 'princess_active_green', 'heart_active_green',
     'tapper','tapper_active','tapper_active_green'
    ]
    const svgIcon = computed(() => {
        if (headerSvgs.includes(props.type)) {
            return `<use xlink:href="/svg/shape.svg#${props.type}"> </use>`;
        } 
    });
  
</script>


<style>


</style>
