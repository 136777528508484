<template>
    <div>
        <div>
            <div class="top_bar">
                <!-- <router-link v-if="!$country.is_india" :to="localePath('/fine-jewelry')">Shop More, Shine More: Get Free Diamond Jewelry On $3000+ Purchases!</router-link> -->
                <!-- <router-link v-else :to="localePath('/fine-jewelry')">Let’s Connect Heart to Heart | Shop Now!</router-link> -->
                <NuxtLink :to="localePath('/engagement-rings')">Get 35% off* on Engagement Rings | Shop Now</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp()  
    
    const openPopup = ref(false)

    const hideModal = () => {
        openPopup.value = false
    }
</script>

<style scoped>
.uppercase{
    text-decoration: underline;
}

.top_bar {
    background: #405955;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
}
.top_bar_mod {
    background: #ae556b;
 
}
.top_bar a{
    color: #fff;
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.black_friday_stripe{
    background: #5c041d;
    text-align: center;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
}

@media(max-width: 1024px) {
    .top_bar a {
        width: auto;
    }
}

@media(max-width: 767px){
    .top_bar{
        padding: 5px;
    }
}
</style>
