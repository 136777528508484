export default defineNuxtPlugin((nuxtApp) => {

    const setAnalytics = async () => {
        let currencyCode;

        // Wait for the app to be ready
        nuxtApp.hook('app:mounted', () => {
        
            //------------- load google tag manager script -------------//
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
                });
                const f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', useRuntimeConfig().public.gtagKey);

            // fb ind
            if( nuxtApp.$country.location == 'IN' ){
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', 1582392115957624);
                fbq('track', 'PageView');

            }

            //tiktok
            if( nuxtApp.$country.location == 'US' ){

                !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
                  var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
                  ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                    ttq.load('CRMM1MBC77U40MO99EJG');
                    ttq.page();
                  }(window, document, 'ttq');
            }


            currencyCode = nuxtApp.$country.currency_sign;


        });

        

        function trigerfb(eventName, data){
            
            try {
                // let currentUrl = window?.location?.href
                // if(eventName !== 'loose diamonds'){
                //     $fetch(useCountryPath('/send-facebook-meta-event'), {
                //         method:'POST',
                //         body: {eventName,data, currentUrl }
                //     })
                // }
                // else{
                //     //to check how many times it is getting called
                //     console.log('looseImpressionGtag called')
                // }

                if (typeof fbq === "function") { 
                    // call api
                    fbq('track', eventName, data)
                    console.log('tag triggered',  eventName, data)        
                }
            } catch (error) {
                
                console.log('error on fbq event', error)
            }
        }

        function trigerTT(eventName, data){
            try {
                
                if (ttq != undefined && ttq.track) { 
        
                    ttq.track(eventName, data)
                    console.log('ttq tag triggered',  eventName, data)        
                }
            } catch (error) {
                
                console.log('error on ttq event', error)
            }
    
    
        }
    
        //------------- Register GTAG -------------//
        nuxtApp.provide('registerGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            if (data.status === 'load') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "start step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": true
                });

                trigerfb('account creation start step 1',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "start step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": true
                })
            }
            if (data.status === 'submit') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "submit step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('account creation submit step 1',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "submit step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
                
            }
            if (data.status === 'complete') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "complete step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('account creation complete step 1',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "complete step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })


                window.dataLayer.push({
                    'event':'form1',
                    'enhanced_conversion_data': {
                    "email": data.data.email,
                    "phone_number": ''
                    }
                })
            }
        })

        // inject trigerTT(eventName, data)
        nuxtApp.provide('trigerTTViewContent', (eventName, data) => {
            trigerTT(eventName, {
                "value": data.price, // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name": data.name // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })
        })

        //------------- Social media GTAG -------------//
        nuxtApp.provide('socialGtag', (status) => { 
            window.dataLayer = window.dataLayer || [];
        
            if (status === 'facebook') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://www.facebook.com/FriendlyDiamonds',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'twitter') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://twitter.com/FriendlyDiam",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://twitter.com/FriendlyDiam',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://twitter.com/FriendlyDiam",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'instagram') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://www.instagram.com/friendly.diamonds/',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'linkedin') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });
                trigerfb('https://www.linkedin.com/company/friendly-diamonds',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
        })

        //------------- newsletter GTAG -------------//
        nuxtApp.provide('newsletterGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "newsletter subscription", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            });

            trigerfb('newsletter subscription submit step 1',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "newsletter subscription", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            })
        
        })

        //------------- cyo choose setting GTAG -------------//
        nuxtApp.provide('choosesettingGtag', (moduleSubType) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb( moduleSubType +" creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
        
        })

        //------------- cyo select setting GTAG -------------//
        nuxtApp.provide('selectsettingGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
            
        })

        //------------- cyo choose diamond GTAG -------------//
        nuxtApp.provide('choosediamondGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
        
        })

        //------------- cyo select diamond GTAG -------------//
        nuxtApp.provide('selectdiamondGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            })
            
        })

        //------------- cyo complete page  GTAG -------------//
        nuxtApp.provide('loadcompleteGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation start step 3",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
            
        })

        //------------- cyo add to cart  GTAG -------------//
        nuxtApp.provide('cyotoCartGtag', (moduleSubType, data) => { 
            
            let products = [{
                "id": data.setting.id,
                "brand": "friendly diamonds",
                "name": data.setting.activeTitle,
                "variant": data.setting.activeMetal,
                "price": data.setting.activePrice,
                "quantity": 1
            }]
            if (moduleSubType != 'earring') {
                products.push({
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": data.diamond.title,
                    "variant": `${data.diamond.cut_grade} Cut, ${data.diamond.color} color ,  ${data.diamond.clarity} Clarity`,
                    "price": parseFloat(data.diamond.price),
                    "quantity": 1
                })
                if(data.merchant_id){
                    products.push({
                        "id": data.merchant_id,
                        "brand": "friendly diamonds",
                        "name": data.setting.activeTitle,
                        "variant": data.setting.activeMetal,
                        "price": parseFloat(data.setting.activePrice) + parseFloat(data.diamond.price),
                        "quantity": 1
                    })
                }
                
            }
            if (moduleSubType === 'earring') {
                products.push({
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": `${data.diamond.weight_one} Total Carat ${data.diamond.shape_one} Lab Grown Diamond`,
                    "variant": `${data.diamond.cut_grade_one} Cut, ${data.diamond.color_one} color ,  ${data.diamond.clarity_one} Clarity`,
                    "price": parseFloat(data.diamond.price_one),
                    "quantity": 1
                }, {
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": `${data.diamond.weight_two} Total Carat ${data.diamond.shape_two} Lab Grown Diamond`,
                    "variant": `${data.diamond.cut_grade_two} Cut, ${data.diamond.color_two} color ,  ${data.diamond.clarity_two} Clarity`,
                    "price": parseFloat(data.diamond.price_two),
                    "quantity": 1
                })
            }
            if (data.appraisal === 1) {
                products.push({
                    "id": 999999999,
                    "brand": "friendly diamonds",
                    "name": "appraisal",
                    "variant": "",
                    "price": 50,
                    "quantity": 1
                })
            }
            //submit step 3
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false
            });

            trigerfb(moduleSubType + " creation submit step 3",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false
            })

        
            //add to cart push prodcts to datalayer 
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.total),
                    "add": {
                        "actionField": {
                            "list": moduleSubType + " complete page"
                        },
                        "products": products
                    }
                }
            });

            trigerfb('Add to cart ',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "Add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.total),
                    "add": {
                        "actionField": {
                            "list": moduleSubType + " complete page"
                        },
                        "products": products
                    }
                }
            })

            trigerTT('AddToCart', {
                "value": parseFloat(data.total), // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.setting.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name": data.setting.activeTitle // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })

        })

    
        //------------- loose diamond impression GTAG -------------//
        nuxtApp.provide('looseImpressionGtag', (data) => { 
            let listing = []
            data.filter((item) => { 
                if (item.length > 0) {
                    listing.push({
                        "id": item.id,
                        "brand": "friendly diamonds",
                        "name": item.title,
                        "variant": `${item.cut_grade} Cut, ${item.color} Color, ${item.clarity} Clarity,  LotNo. ${item.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(item.price),
                        "list": "loose diamonds",
                        "position": 1
                    })
                }
            })

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            });

            trigerfb('loose diamonds product impression view',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            })
        
        })

        //------------- view loose diamond GTAG -------------//
        nuxtApp.provide('looseViewGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "list": "loose diamonds",
                            "position": 1
                        }]
                    }
                }
            });

            trigerfb('loose diamonds product impression click',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "list": "loose diamonds",
                            "position": 1
                        }]
                    }
                }
            })
            
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('loosetoCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('Add to cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "Add to cart", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })

            trigerTT('AddToCart', {
                "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name": data.title // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentInitiateGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment start step 1',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentCreationGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment submit step 1',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentSucessGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "complete step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment complete step 1',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "complete step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  remove from cart GTAG -------------//
        nuxtApp.provide('removefromCartGtag', (item) => { 
            let products = []
            products.push(item)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            });

            trigerfb('remove from cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            })
        
        })

        //-------------  checkout address GTAG -------------//
        nuxtApp.provide('addressGtag', (data) => { 
            let products = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 1", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 1
                        },
                        "products": products
                    }
                }
            });

            trigerfb('InitiateCheckout',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 1", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 1
                        },
                        "products": products
                    }
                }
            })
        
        })

        nuxtApp.provide('submitAddressGtag', (data) => { 
        
            window.dataLayer.push({
                'event':'form1',
                'enhanced_conversion_data': {
                "email": data.email,
                "phone_number": data.phone.replace(/\s/g, '')
                }
            })
        })

        //-------------  checkout payment GTAG -------------//
        nuxtApp.provide('paymentGtag', (data) => { 
            let products = []
            let tt_product = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 2", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 2
                        },
                        "products": products
                    }
                }
            });

            trigerfb('checkout',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 2", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 2
                        },
                        "products": products
                    }
                }
            })

            trigerTT('InitiateCheckout',{
                "value":  data.total_label, // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": tt_product
            })
        })

        //-------------  checkout payment type GTAG -------------//
        nuxtApp.provide('paymentTypeGtag', (pay_type) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 3", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "checkout_option": {
                        "actionField": {
                            "step": 3,
                            "option": pay_type
                        }
                    }
                }
            });

            trigerfb('checkout',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 3", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "checkout_option": {
                        "actionField": {
                            "step": 3,
                            "option": pay_type
                        }
                    }
                }
            })

            trigerTT('AddPaymentInfo', {
                "value": 1, // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                  {
                    "content_id": "pay type selection", // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name": pay_type // string. The name of the page or product. Example: "shirt".
                  }
                ]
              })
        })

        //-------------  checkout payment success GTAG -------------//
        nuxtApp.provide('paymentSuccessGtag', (data, trans_id) => { 
            let products = []
            let tt_products = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                    tt_products.push(item.tiktok)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "purchase", // DO NOT CHANGE
                "eventLabel": "webshop", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "purchase": {
                        "actionField": {
                            "id": trans_id,
                            "affiliation": "webshop",
                            "revenue": data.total_label,
                            "tax": data.tax_label,
                            "shipping": 0.00,
                            "coupon": undefined,
                            "commission": 'default'
                        },
                        "products": products
                    }
                }
            });

            trigerfb('purchase',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "purchase", // DO NOT CHANGE
                "eventLabel": "webshop", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "purchase": {
                        "actionField": {
                            "id": trans_id,
                            "affiliation": "webshop",
                            "revenue": data.total_label,
                            "tax": data.tax_label,
                            "shipping": 0.00,
                            "coupon": undefined,
                            "commission": 'default'
                        },
                        "products": products
                    }
                }
            })

            trigerTT('CompletePayment',{
                "value":  data.total_label, // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": tt_products
            })

            if (typeof gtag === "function" && currencyCode == 'INR') { 
    
    
                gtag('event', 'conversion', {
                    'send_to': 'AW-16468839012/AlKuCNKjiaoZEOSU-qw9',
                    'value': 1.0,
                    'currency': currencyCode,
                    'transaction_id': trans_id
                });
            
                
            }
        })

        nuxtApp.provide('jewelryImpressionGtag', (data) => { 

            if(! (data && data.length) ){

                return
            }

            let listing = []
            data.filter((item) => { 

                if( !(item.metals && item.metals.length > 0) ){

                    return false
                }

                item.metals.find((list) => { 
                    if(list.selected === 1){
                        listing.push({
                            "id": item.id,
                            "brand": "friendly diamonds",
                            "name": item.product_name,
                            "variant": list.name,
                            "category": "jewelry/" + item.sub_type,
                            "price": parseFloat(list.price),
                            "list": item.sub_type,
                            "position": 1
                        })
                    }
                })
            })
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            });

            trigerfb('category page',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            })
        })

        nuxtApp.provide('selectJewelryGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carats.find((item) => item.selected === 1).id,
                            "brand": "friendly diamonds",
                            "name": data.data.title,
                            "variant": "",
                            "category": "jewelry/" + data.sub_module,
                            "price": parseFloat(data.carats.find((item) => item.selected === 1).price),
                            "list": "category page",
                            "position": 1
                        }]
                    }
                }
            });

            trigerfb('category page',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carats.id,
                            "brand": "friendly diamonds",
                            "name": data.data.title,
                            "variant": "",
                            "category": "jewelry/" + data.sub_module,
                            "price": parseFloat(data.carats.price),
                            "list": "category page",
                            "position": 1
                        }]
                    }
                }
            })
            
        })

        nuxtApp.provide('jewelryDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.carat.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price)
                        }]
                    }
                }
            });

            trigerfb('product detail view',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.carat.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('jewelryToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.carat.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.metal.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('Add to cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "Add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.carat.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.metal.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price),
                            "quantity": 1
                        }]
                    }
                }
            })

            trigerTT('AddToCart', {
                "value": parseFloat(data.carat.price), // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.carat.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name":  data.metal.product_name // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })
        })

        nuxtApp.provide('presetDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            });

            trigerfb('product detail view',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('presetToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('Add to cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "Add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })

            trigerTT('AddToCart', {
                "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name":  data.product_name // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })
        })

        nuxtApp.provide('bandDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            });

            trigerfb('product detail view',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('bandToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('Add to cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "Add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })

            trigerTT('AddToCart', {
                "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
                "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
                "contents": [
                    {
                        "content_id": data.id, // string. ID of the product. Example: "1077218".
                        "content_type": "product_group", // string. Either product or product_group.
                        "content_name":  data.product_name // string. The name of the page or product. Example: "shirt".
                    }
                ]
            })
        })

    };
  
    setAnalytics().catch(console.error);
  
  });
  