import { defineNuxtRouteMiddleware, useNuxtApp, useRequestHeaders, useCookie } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {

  const defaultStore = 'us'

  const {public: config} = useRuntimeConfig()

  // test commit
  // return

  const { isCrawler } = useDevice()
  if( isCrawler ){

    return
  }

  if( config.enableLocalization !== 'true' ){

    return
  }
  // console.log( 'path starts with',to.path.startsWith('/_nuxt')   )
  if( to.path.startsWith('/_nuxt') ){

    return
  }


  if( import.meta.server && !to.path.startsWith('/api') ){

    const disableLocaleFlag = to.query.disable_localization
    
    if (disableLocaleFlag) {

      const cookie = useCookie('disable_localization', {
        path: '/',
        httpOnly: true,
        sameSite: 'lax',
      })

      cookie.value = 'true'
    }

    // Check if 'disable_localization' is set in cookie
    const checkIfLocaleDisabled = useCookie('disable_localization')
    if (checkIfLocaleDisabled.value == true || disableLocaleFlag) {

      return
    }

    let countryCode = 'us'

    // Continue to localization if flag is not present
    let cfCountryCode = useRequestHeader('cf-ipcountry')
    let vercelCountryCode = useRequestHeader('x-vercel-ip-country')

    if( cfCountryCode && typeof cfCountryCode == 'string' ){

      countryCode = cfCountryCode.toLocaleLowerCase()
    }
    else if( vercelCountryCode && typeof vercelCountryCode == 'string'  ){

      countryCode = vercelCountryCode.toLocaleLowerCase()
    }



    if (!countryCode && defaultStore) {
      countryCode = defaultStore
    }

    const redirectionMap = {
      'in': '/in',
    };

    const targetPath = redirectionMap[countryCode];

    if (targetPath && !to.path.startsWith(targetPath)) {

      const path = to.path === '/' ? targetPath : targetPath

      return navigateTo( path, { redirectCode: 302 })
    }
  }

})